const axios = require('axios');

// const instance = axios.create({
//     baseURL: 'https://bz.yiwuchao.cn/',
//     timeout: 1000,
//   });

export const http = (option) => {
    return new Promise((resolve, reject) => {
        if (!option) {
            reject('配置不能为空');
            return;
        }

        if (!option.url) {
            reject('url 不能为空');
            return;
        }

        axios({
            method: option.method || 'post',
            url: option.url,
            data: option.data,
            headers: option.headers || { // 修正为 headers 而不是 header
                'Content-Type': 'application/json; charset=utf-8' // 修正 'contend-type' 为 'Content-Type'
            },
        }).then(response => {
            console.log(response)
            const data = response.data;
            if (data && data.code === 0) { // 检查 code 是否为 0，表示成功
                resolve(data.data);
            } else {
                reject(`[${data.code}] ${data.msg}`);
            }
        }).catch(err => {
            if (err.response && err.response.data) {
                reject(err.response.data);
            } else {
                reject(err.message || err);
            }
        });
    });
};

export default axios;
