<template>
  <div class="login-container">
    <h3>扫码登录</h3>
    <div style="height: 300px; display: flex; align-items: center; justify-content: center;" v-loading="loading">
      <div style="width: 250px; height: 250px; position: relative;">
        <img :src="img" style="width: 100%; height: 100%;" alt=""/>
        <div
          v-if="cover"
          style="width: 100%; height: 100%; background-color: rgba(255, 255, 255, .8); position: absolute; top: 0; line-height: 250px; color: #409eff;">
          <el-button
            type="primary"
            text
            bg
            @click="qrcode"
            v-if="statusFresh"
          >点击刷新
          </el-button>
          <el-button
            type="primary"
            text
            bg
            @click="qrcode"
            v-if="statusLogout"
          >退出登录
          </el-button>
        </div>
      </div>
    </div>
    <div> 请使用<span style="color: #1875bf">网易云音乐APP</span>扫码</div>
    <div style="margin-top: 50px; font-size: 12px; color: #676767;"> 暂不支持其他方式登录</div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { http } from '@/common/axios';
import { useLoginStatusStore } from '@/stores/login';
import { ElMessage } from 'element-plus';
import { defineEmits } from 'vue';

const emit = defineEmits(['loginSuccess']); // 定义登录成功事件
const router = useRouter(); // 引入 Vue Router

const loading = ref(false);
const key = ref('');
const url = ref('');
const img = ref('');

const store = useLoginStatusStore();
const status = computed(() => store.status);

const statusFresh = computed(() => status.value === -1);
const statusLogout = computed(() => status.value === 1);
const cover = computed(() => (statusFresh.value || statusLogout.value));

onMounted(async () => {
  await checkLoginStatus(); // 检查登录状态
  if (status.value !== 1) {
    load(); // 仅在未登录时加载二维码
  }
});

const checkLoginStatus = async () => {
  loading.value = true;
  try {
    const response = await http({ url: '/api/login/check', data: {} });
    console.log('Login status response:', response); // 调试输出

    if (response && response.status === 1) {
      store.setStatus(1); // 设置登录状态为已登录
      console.log('User is logged in, redirecting to upload...');
      router.push('/upload'); // 自动跳转到上传区域
    } else {
      store.setStatus(0); // 如果未登录，设置为未登录状态
      console.log('User is not logged in, loading QR code...');
    }
  } catch (error) {
    console.error('Error checking login status:', error);
  } finally {
    loading.value = false;
  }
};

const load = () => {
  loading.value = true;
  http({
    url: '/api/login/check',
    data: {}
  }).then(data => {
    store.setStatus(data.status);

    if (data.status !== 1) {
      qrcode();
    }
  }).catch(err => {
    console.log(err);
  }).finally(() => {
    loading.value = false;
  });
};

const qrcode = () => {
  store.setStatus(0);
  loading.value = true;
  http({
    url: '/api/login/qrcode',
    data: {}
  }).then(data => {
    key.value = data.key;
    url.value = data.qrurl;
    img.value = data.qrimg;

    store.setStatus(0);

    setTimeout(() => {
      state();
    }, 5000);
  }).catch(err => {
    console.log(err);
  }).finally(() => {
    loading.value = false;
  });
};

const state = () => {
  http({
    url: '/api/login/code-state',
    data: {
      key: key.value
    }
  }).then(data => {
    store.setStatus(data.status);

    if (data.status === 1) {
      ElMessage({ message: '登录成功，请在右侧选择文件上传！', type: 'success' });
      emit('loginSuccess'); // 触发登录成功事件
      router.push('/upload'); // 登录成功后跳转到上传区域
      return;
    }

    if (data.status !== -1 && data.status !== 1) {
      setTimeout(() => {
        state();
      }, 5000);
    }
  }).catch(err => {
    console.log(err);
  });
};
</script>

<style scoped>
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}
</style>
