<template>
  <div class="upload-container">
    <div class="header">
      <el-button type="primary" @click="goBack" class="back-button">返回登录</el-button>
      <h1>歌手列表</h1>
    </div>

    <!-- 显示目录数据 -->
    <el-table :data="paginatedDirectories" border style="width: 100%;" @selection-change="handleSelectionChange">
      <el-table-column type="selection" label="选择"></el-table-column>
      <el-table-column prop="name" label="文件名"></el-table-column>
      <el-table-column prop="sizeDesc" label="大小"></el-table-column>
      <el-table-column prop="count" label="数量"></el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      @current-change="handlePageChange"
      :current-page="currentPage"
      :page-size="pageSize"
      :total="directories.length"
      layout="prev, pager, next"
      class="pagination"
    ></el-pagination>

    <!-- 提交上传按钮 -->
    <div class="button-container">
      <el-button type="primary" @click="submitUpload" :loading="isLoading" :class="shake ? 'animate__animated animate__shakeX' : ''">
        点击提交
        <el-icon class="el-icon--right">
          <Upload />
        </el-icon>
      </el-button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { ElMessage, ElNotification } from 'element-plus';
import { Upload } from '@element-plus/icons-vue';
import { useRouter } from 'vue-router';
import crypto from 'crypto-js';

const router = useRouter();
const directories = ref([]);
const selectedDirectories = ref([]);
const currentPage = ref(1);
const pageSize = ref(10);
const isLoading = ref(false);
const shake = ref(false);

const paginatedDirectories = computed(() => {
  const start = (currentPage.value - 1) * pageSize.value;
  const end = start + pageSize.value;
  return directories.value.slice(start, end);
});

const fetchDirectories = async () => {
  isLoading.value = true;
  const baseCDNURL = 'https://fastly.jsdelivr.net/gh/Cinvin/cdn@1.0.13/artist/';

  try {
    const summaryResponse = await fetch(`${baseCDNURL}summary.json`);
    if (!summaryResponse.ok) throw new Error(`无法获取 summary.json 数据: ${summaryResponse.status} ${summaryResponse.statusText}`);
    const summaryData = await summaryResponse.json();

    const topResponse = await fetch(`${baseCDNURL}top.json`);
    if (!topResponse.ok) throw new Error(`无法获取 top.json 数据: ${topResponse.status} ${topResponse.statusText}`);
    const topData = await topResponse.json();

    const allData = [...summaryData, ...topData];
    directories.value = allData;

  } catch (error) {
    console.error('Error fetching directories:', error);
    ElMessage({ message: `获取目录数据失败: ${error.message}`, type: 'error' });
  } finally {
    isLoading.value = false;
  }
};

const handleSelectionChange = (rows) => {
  selectedDirectories.value = rows;
};

const handlePageChange = (page) => {
  currentPage.value = page;
};

const fetchFileDetails = async (id) => {
  const baseCDNURL = 'https://fastly.jsdelivr.net/gh/Cinvin/cdn@1.0.13/artist/';
  try {
    const response = await fetch(`${baseCDNURL}${id}.json`);
    if (!response.ok) throw new Error(`无法获取文件详情: ${response.status} ${response.statusText}`);
    return await response.json();
  } catch (error) {
    console.error('Error fetching file details:', error);
    ElMessage({ message: `获取文件详情失败: ${error.message}`, type: 'error' });
    return null;
  }
};

const calculateMD5 = (fileContent) => {
  return crypto.MD5(fileContent).toString();
};

const submitUpload = async () => {
  if (selectedDirectories.value.length <= 0) {
    ElMessage({ message: '请选择目录', type: 'error' });
    return;
  }

  isLoading.value = true;
  const md5s = [];
  try {
    for (const directory of selectedDirectories.value) {
      const fileDetails = await fetchFileDetails(directory.id);
      if (fileDetails) {
        for (const file of fileDetails.data) {
          const md5 = calculateMD5(file.data);
          if (md5) {
            md5s.push({ id: file.id, md5: md5 });
          }
        }
      }
    }

    if (md5s.length === 0) {
      ElMessage({ message: '没有计算出任何 MD5 值', type: 'error' });
      return;
    }

    const response = await fetch('/api/upload-md5s', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(md5s)
    });

    if (!response.ok) throw new Error(`上传失败: ${response.status} ${response.statusText}`);

    ElNotification({ title: '成功', message: '文件上传成功', type: 'success' });
  } catch (error) {
    console.error('Error uploading files:', error);
    ElMessage({ message: `上传失败: ${error.message}`, type: 'error' });
  } finally {
    isLoading.value = false;
  }
};

const goBack = () => {
  router.push('/');
};

onMounted(() => {
  fetchDirectories();
});
</script>

<style scoped>
.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 20px;
}

.header .back-button {
  margin-right: auto;
}

.pagination {
  margin-top: 20px;
}

.button-container {
  margin-top: 20px;
}
</style>
