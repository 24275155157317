import { createRouter, createWebHistory } from 'vue-router';
import Login from '../components/Login.vue';
import Upload from '../components/Upload.vue';

// 创建一个简单的 mock 登录检查函数
const isAuthenticated = () => {
  // 实际应用中你会检查用户的实际登录状态，比如从 localStorage 或 Vuex 状态管理中获取
  return !!localStorage.getItem('user-token');
};

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next('/upload');
      } else {
        next();
      }
    }
  },
  {
    path: '/upload',
    name: 'Upload',
    component: Upload,
    beforeEnter: (to, from, next) => {
      if (!isAuthenticated()) {
        next('/');
      } else {
        next();
      }
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
