<template>
  <div id="app">
    <el-container class="full-height">
      <el-aside width="100%" class="login-section" v-if="!isLoggedIn">
        <Login @loginSuccess="handleLoginSuccess"></Login>
      </el-aside>
      <el-main v-if="isLoggedIn" class="upload-section">
        <Upload></Upload>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import Login from './components/Login.vue';
import Upload from './components/Upload.vue';

export default {
  name: 'App',
  components: {
    Login,
    Upload
  },
  data() {
    return {
      isLoggedIn: false // 初始状态为未登录
    };
  },
  methods: {
    handleLoginSuccess() {
      this.isLoggedIn = true; // 登录成功后切换界面
    }
  }
};
</script>

<style>
#app {
  height: 100vh; /* 使容器充满整个视窗 */
}

.full-height {
  height: 100%; /* 确保容器占满高度 */
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
}

.login-section, .upload-section {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; /* 使登录区和上传区都宽度自适应 */
}

.upload-section {
  flex: 1; /* 占满剩余空间 */
}
</style>
